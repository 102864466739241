import React from 'react';
import {
    Drawer,
    DrawerBody,
    DrawerOverlay,
    DrawerContent,
    useDisclosure,
  } from '@chakra-ui/react';
import { HiOutlineMenuAlt2 } from 'react-icons/hi';
import { IoHomeOutline } from "react-icons/io5";
import {Link, useLocation} from 'react-router-dom';
import { MdOutlineHelp, MdOutlineHome, MdOutlineHomeMini, MdOutlineNote, MdOutlinePhoneInTalk, MdOutlineProductionQuantityLimits } from "react-icons/md";



const MobileNav = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const btnRef = React.useRef();
    const location = useLocation();
    const pathname = location.pathname;

    return (
        <>
        <HiOutlineMenuAlt2 color='black' className='cursor-pointer' size={28} ref={btnRef} onClick={onOpen}/>
        <Drawer
            isOpen={isOpen}
            onClose={onClose}
            finalFocusRef={btnRef}
            placement='left'
            size='xs'
        >
            <DrawerOverlay />
            <DrawerContent className='rounded-r-3xl'>
                <div className='flex justify-end p-2'>
                    <img src='../../icons/x.png' className='w-10 cursor-pointer' onClick={onClose}/>
                </div>
            <DrawerBody>
                <div className='flex justify-between flex-col'>
                    <div className='font-Roboto'>
                        <div className='flex justify-center mb-8'>
                            <img src='../../logo.png' className='xs:h-14 md:h-24 rounded-md'/>
                        </div>
                        <Link to="/" className={pathname === "/" ? 'flex items-center gap-4 bg-gray-200 p-2 rounded-md cursor-pointer' : 'flex items-center gap-4 cursor-pointer p-2 rounded-md'}>
                            <MdOutlineHomeMini size={20}/>
                            <h1 className='text-md'>Нүүр</h1>
                        </Link>
                        <Link to="/address" className={pathname === "/address" ? 'flex items-center gap-4 bg-gray-200 p-2 rounded-md cursor-pointer mt-2' : 'flex items-center gap-4 cursor-pointer p-2 mt-2 rounded-md'}>
                            <MdOutlineHome size={20}/>
                            <h1 className='text-md'>Хаяг холбох</h1>
                        </Link>
                        <Link to="/product-check" className={pathname === "/product-check" ? 'flex items-center gap-4 bg-gray-200 p-2 rounded-md cursor-pointer mt-2' : 'flex items-center gap-4 cursor-pointer p-2 mt-2 rounded-md'}>
                            <MdOutlineProductionQuantityLimits size={20}/>
                            <h1 className='text-md'>Миний захиалгууд</h1>
                        </Link>
                        <Link to="/service" className={pathname === "/service" ? 'flex items-center gap-4 bg-gray-200 p-2 rounded-md cursor-pointer mt-2' : 'flex items-center gap-4 cursor-pointer p-2 mt-2 rounded-md'}>
                            <MdOutlineNote size={20}/>
                            <h1 className='text-md'>Үйлчилгээний нөхцөл</h1>
                        </Link>
                        <Link to="/lesson" className={pathname === "/lesson" ? 'flex items-center gap-4 bg-gray-200 p-2 rounded-md cursor-pointer mt-2' : 'flex items-center gap-4 cursor-pointer p-2 mt-2 rounded-md'}>
                            <MdOutlineHelp size={20}/>
                            <h1 className='text-md'>Заавар сургалт</h1>
                        </Link>
                        <Link to="/contact" className={pathname === "/contact" ? 'flex items-center gap-4 bg-gray-200 p-2 rounded-md cursor-pointer mt-2' : 'flex items-center gap-4 cursor-pointer p-2 mt-2 rounded-md'}>
                            <MdOutlinePhoneInTalk size={20}/>
                            <h1 className='text-md'>Холбоо барих</h1>
                        </Link>
                    </div>
                    <h1 className='mt-20 text-center font-Roboto font-bold'>Skylogistic 2024 он</h1>
                    <h1 className='mt-2 text-center text-sm font-Roboto'>Giilen.mn хөгжүүлэв.</h1>
                </div>
            </DrawerBody>
            </DrawerContent>
        </Drawer>
        </>
    )
}

export default MobileNav